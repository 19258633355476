import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { DerivedEmail, DeriveResult } from '../DeriveResult'
import { APIRequestBuilder, APIError, HandledAPIError } from '../../utils/APIConfig'
import { GuesserForm } from '../GuesserForm'
import { UsageCard } from './UsageCard'
import './DeriveContainer.css'

const DeriveContainer = () => {
  const [resultData, setResultData] = useState<DerivedEmail[]>([])
  const [showResult, setShowResult] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<HandledAPIError | undefined>(undefined)

  const handleFormSubmit = async (fullName: string, companyDomain: string) => {
    setLoading(true)
    setError(undefined)
    setResultData([])

    try {
      const response = await APIRequestBuilder.post('/api/v1/derive-email', {
        fullName,
        companyDomain,
      })

      setResultData(response.data.emails)
    } catch (apiError) {
      catchAPIError(apiError as APIError)
    } finally {
      setShowResult(true)
      setLoading(false)
    }
  }

  const handleHideResult = () => {
    setShowResult(false)
  }

  const catchAPIError = (apiError: APIError) => {
    setError({
      message: apiError?.response?.data?.error || error?.message,
      code: apiError?.response?.status,
    })
  }

  return (
    <Container className='mt-2'>
      <Row>
        <Col className='col-md-3'>
          <UsageCard />
        </Col>
        <Col className='col-md-8'>
          <div className='card mb-4 d-flex'>
            <div className='card-body align-items-center col-md-8 offset-md-2'>
              {showResult ? (
                <DeriveResult emails={resultData} error={error} onClickBack={handleHideResult} />
              ) : (
                <GuesserForm onSubmit={handleFormSubmit} isLoading={isLoading} />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default DeriveContainer
