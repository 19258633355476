import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import './GuesserForm.css'

interface GuesserFormProps {
  onSubmit: (fullName: string, companyDomain: string) => void,
  isLoading: boolean,
}

export const GuesserForm: React.FC<GuesserFormProps> = ({ onSubmit, isLoading }) => {
  const [fullName, setFullName] = useState('')
  const [companyDomain, setCompanyDomain] = useState('')

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    onSubmit(fullName, companyDomain)
  }

  return (
    <>
      <h4>Derive the email addresses for the company member! 🤔</h4>
      <hr />
      <Form onSubmit={handleSubmit}>
        <Form.Group className='mb-3' controlId='formDomain'>
          <Form.Label>Person Full Name</Form.Label>
          <Form.Control
            type='text'
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            placeholder='Joe Due'
            required
            autoComplete='off'
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formFullName'>
          <Form.Label>Company Domain</Form.Label>
          <Form.Control
            type='text'
            value={companyDomain}
            onChange={(e) => setCompanyDomain(e.target.value)}
            placeholder='example.com'
            required
            autoComplete='off'
          />
        </Form.Group>
        <Form.Group className='mb-3'>
          <Button variant='primary' type='submit' disabled={isLoading}>
          { isLoading ? 'Loading …' : 'Submit' }
          </Button>
        </Form.Group>
      </Form>
    </>
  )
}
