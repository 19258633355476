import React from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BsArrowLeft, BsClipboard, BsExclamationOctagon } from 'react-icons/bs'
import { AiFillInfoCircle } from 'react-icons/ai'
import { HandledAPIError } from '../../utils/APIConfig'
import ErrorCard from './ErrorCard'
import './DeriveResult.css'

interface DeriveResultProps {
  emails: DerivedEmail[]
  error?: HandledAPIError
  onClickBack: () => void
}

export interface DerivedEmail {
  email: string
  confidence: number
  totalCountInDataset: number
  thisFormatCountInDataset: number
}

export const DeriveResult: React.FC<DeriveResultProps> = ({ emails, error, onClickBack }) => {
  const hasNoEmails = emails.length === 0

  const renderTooltip = (content: string) => <Tooltip id='tooltip'>{content}</Tooltip>

  const renderEmails = () => {
    return (
      <ul className='list-group'>
        {emails.map((derive, index) => (
          <li
            className='list-group-item d-flex justify-content-between align-items-center'
            key={index}
          >
            <b>{derive.email}</b>

            <OverlayTrigger
              placement='top'
              overlay={renderTooltip(
                `${derive.thisFormatCountInDataset} of ${derive.totalCountInDataset} emails of the domain with this format found in the dataset.`,
              )}
            >
              <small className='text-small'>
                Confidence Score: {derive.confidence}%
                <AiFillInfoCircle />
              </small>
            </OverlayTrigger>
            <OverlayTrigger placement='top' overlay={renderTooltip('Copy to clipboard!')}>
              <span
                onClick={() => {
                  navigator.clipboard.writeText(derive.email)
                }}
              >
                <BsClipboard />
              </span>
            </OverlayTrigger>
          </li>
        ))}
      </ul>
    )
  }

  const renderErrorMessage = () => {
    if (error && error.code && error.code >= 500) {
      return (
        <ErrorCard errorMessage='There was an internal server error. Please try again later.' />
      )
    }

    if (error && error.message) {
      return <ErrorCard errorMessage={error.message} />
    }

    if (error && hasNoEmails) {
      return <ErrorCard errorMessage='There was an unexpected error. Please try again later.' />
    }

    return null
  }

  const renderNoEmailsMessage = () => {
    if (hasNoEmails && !error) {
      return (
        <div className='text-center'>
          <h1>
            <BsExclamationOctagon className='mr-2' />
          </h1>
          The email has not been derived! There is no email in our dataset with the provided domain.
        </div>
      )
    }

    return null
  }

  return (
    <>
      {renderErrorMessage()}
      {renderNoEmailsMessage()}
      {emails.length > 0 && (
        <>
          <legend>Good News! The email has been derived for you! 🎉</legend>

          {renderEmails()}
        </>
      )}

      <Button className='mt-3' variant='primary' onClick={onClickBack}>
        <BsArrowLeft className='pr-2' />
        Try Again
      </Button>
    </>
  )
}
