import React from 'react'
import { BsMegaphone } from 'react-icons/bs'

export const UsageCard = () => {
  return (
    <div className='card mb-4 d-flex'>
      <div className='card-body align-items-center'>
        <h5>
          <BsMegaphone className='mr-2' />
          <span className='ml-2'>&nbsp; Usage</span>
        </h5>
        <hr />
        <p>
          This tool is used to derive the email of a person given their full name and the domain of
          the company they work for.
        </p>
        <p>
          <strong>Full Name</strong> - The full name of the person you are trying to find the email
          for.
        </p>
        <p>
          <strong>Company Domain</strong> - The domain of the company the person works for.
        </p>
        <p>
          <strong>Confidence Score</strong> - The percentage of emails in the dataset that match the
          format of the email we derived.
        </p>
      </div>
    </div>
  )
}
