import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import Layout from './layout/Layout'
import DeriveContainer from './components/DeriveContainer/DeriveContainer'
import './App.css'

function App() {
  return (
    <>
      <Layout>
        <DeriveContainer />
      </Layout>
    </>
  )
}

export default App
