import React, { ReactNode } from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { BsEnvelopeAtFill } from 'react-icons/bs'
import './Layout.css'

interface LayoutProps {
  children: ReactNode
}

const TopNav = () => {
  return (
    <Navbar bg='dark' expand='lg' className='main-header navbar-dark'>
      <Container fluid>
        <a className='navbar-brand' href='/'>
          <BsEnvelopeAtFill size='30px' style={{marginRight: 5, color: '#14a47a' }} />
          Mailer Guesser
        </a>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='me-auto my-2 my-lg-0 navbar-nav navbar-nav-scroll'>
            <a className='nav-link' href='/'>
              Home
            </a>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <TopNav />
      <div className='main-content-wrap d-flex flex-column'>
        <main className='main-content'>{children}</main>
      </div>
    </>
  )
}

export default Layout
