import React from 'react'
import { Card, Button } from 'react-bootstrap'
import './ErrorCard.css'

interface ErrorCardProps {
  errorMessage: string
}

const ErrorCard: React.FC<ErrorCardProps> = ({ errorMessage }) => {
  return (
    <Card className='error-card'>
      <Card.Header className='error-card-header'>
        <Button variant='danger' size='lg' className='error-button'>
          Error
        </Button>
      </Card.Header>
      <Card.Body>
        <Card.Text className='error-message'>{errorMessage}</Card.Text>
      </Card.Body>
    </Card>
  )
}

export default ErrorCard
