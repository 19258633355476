import axios from 'axios';

export const APIRequestBuilder = axios.create({
  baseURL: process.env.REACT_APP_SERVER_API_URL || 'http://localhost:8000',
  headers: {
    'Content-Type': 'application/json',
  },
})

export interface APIError {
  response?: {
    data?: {
      error: string
    }
    status: number
  }
  message: string
}

export interface HandledAPIError {
  code?: number
  message?: string
}
